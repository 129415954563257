:root {
    --first-column-size: 18%;
    --big-column-size: 37%;
}

.con-section {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.section-a,
.section-b,
.section-d,
.section-e,
.section-f,
.section-g,
.section-h {
    display: grid;
    gap: 2rem;
}

.section-a> :first-child p,
.section-a> :last-child p {
    padding-bottom: 0;
}

.section-b> :first-child {
    padding-top: 2rem;
}

@media (min-width: 1200px) {

    .section-a {
        grid-template-columns: var(--first-column-size) 4fr 4fr 2fr;
    }

    .section-b {
        grid-template-columns: var(--first-column-size) auto;
    }

    .section-d {
        grid-template-columns: var(--first-column-size) 4fr 4fr 2fr;
    }

    .section-e {
        grid-template-columns: var(--first-column-size) 1fr 1fr;
    }

    .section-f {
        grid-template-columns: auto var(--first-column-size);

    }

    .section-g {
        grid-template-columns: var(--big-column-size) auto;

    }

    .section-h {
        grid-template-columns: 1fr 1fr;
    }
}