@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 300;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Thin.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 300;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-ThinItalic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 400;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Light.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 400;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-LightItalic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 500;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Book.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 500;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-BookItalic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 600;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Regular.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 600;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Italic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 700;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Medium.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 700;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-MediumItalic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 800;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-SemiBold.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 800;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-SemiBoldItalic.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: normal;
    font-weight: 900;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-Bold.otf') format("opentype")
}

@font-face {
    font-family: PP Neue Montreal;
    font-style: italic;
    font-weight: 900;
    src: url('/public/assets/fonts/PP Neue Montreal/PPNeueMontreal-BoldItalic.otf') format("opentype")
}

:root {
    /* COLORS */
    --light-pink: #f74f9e;
    --pure-black: #000000;
    --pure-white: #ffffff;

    /* SIZE */
    --button-radius: 1.3rem;

    /* FONTS SIZE */
    --font-size-000: .9rem;
    --font-size-001: .9rem;
    --font-size-002: .96rem;
    --font-size-003: 1rem;

    /* FONTS WEIGHT */
    --font-weight-001: 300;
    --font-weight-002: 400;
    --font-weight-003: 500;
    --font-weight-004: 600;
    --font-weight-005: 700;
    --font-weight-006: 800;
    --font-weight-007: 900;
}

* {
    font-family: 'PP Neue Montreal', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    line-height: 1.5;
    scroll-behavior: smooth;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-align {
    width: 86%;
    margin: 5em auto 8rem auto;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 2.6rem;
}

h2.text-title {
    text-transform: uppercase;
    font-size: var(--font-size-002);
    font-weight: var(--font-weight-007);
    padding-bottom: .6rem;
}

.text-context p,
.text-context a,
.text-context .non-link {
    padding-bottom: 1.2rem;
    font-size: var(--font-size-001);
    font-weight: var(--font-weight-004);
}

.text-context a,
.text-context .non-link {
    color: var(--light-pink);
    cursor: pointer;
    text-decoration: underline;
}

.nav-footer .text-context > * {
    font-weight: var(--font-weight-006);
}

.nav-footer .text-context .non-link {
    padding-bottom: unset;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.d-none {
    display: none;
}

button {
    padding: 6px 10px;
    border-radius: var(--button-radius);
    outline: none;
    border: none;
    font-size: var(--font-size-000);
    font-weight: var(--font-weight-005);
    background: transparent;
}

.flex {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.my-1 {
    margin: 0.8rem 0;
}

label {
    width: 44px;
    height: 23px;
    position: relative;
    display: block;
    background: #fff;
    border-radius: 200px;
    cursor: pointer;
    border: .5px solid #000000
}

canvas {
    touch-action: none; /* Prevents touch events like scrolling */
    -webkit-touch-callout: none; /* Prevents callout menu from appearing */
    -webkit-user-select: none; /* Prevents text selection */
}

#canvas-toggle {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
}

@media (min-width: 714px) {
    :root {
        --font-size-001: 1rem;
        --font-size-002: .98rem;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none;
    }

    .d-lg-block {
        display: block;
    }
}

@media (min-width: 1200px) {
    :root {
        --font-size-001: 1.2rem;
    }
}

@media (min-width: 2500px) {
    :root {
        --font-size-000: 1rem;
        --font-size-001: 1.8rem;
        --font-size-002: 1.4rem;
    }
}