.con-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
}

.con-section .section-a .text-context p.last-child {
    padding-bottom: 0;
}

.con-section .section-c {
    position: fixed;
    width: 28%;
    right: 4%;
}

.con-copy {
    margin-top: 4rem;
}

@media (min-width: 992px) {
    .con-section {
        grid-template-columns: repeat(3, 1fr);
    }
}