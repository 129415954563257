.large-mobile-button,
.medium-mobile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 2rem;
    padding: 6px 10px;
    border: 1px solid transparent;
    cursor: pointer;
}

.medium-mobile-button {
    width: 100%;
    color: #fff;
}

.disabled {
    cursor: default;
    opacity: .5;
}

.large-mobile-button p {
    font-size: var(--font-size-001);
    font-weight: var(--font-weight-005);
    color: #fff;
}

