:root {
    --cuboid-spacing: 12px;
    --second-rectangle-spacing: 10px;
    --slide-common-height: 24px;
    --black-color: #000000;
    --white-color: #ffffff;
    --transition-time: .2s;
}

.cuboid {
    position: relative;
    height: 80vh;
    width: 95%;
}

.cuboid > * {
    position: absolute;
}

.cuboid .first-rectangle,
.cuboid .second-rectangle {
    border: 1px solid;
    width: 100%;
    height: 100%;
}

.cuboid .first-rectangle {
    top: var(--cuboid-spacing);
}

.cuboid .second-rectangle {
    left: var(--cuboid-spacing);
    padding: var(--second-rectangle-spacing) calc(var(--cuboid-spacing) + var(--second-rectangle-spacing)) var(--second-rectangle-spacing) var(--second-rectangle-spacing);
}

.cuboid .connector {
    border-top: 1px solid;
    padding: 8px;
    transform: rotate(-45deg);
}

.canvas-actions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.connector.first-connector {
    top: 4px;
    left: 4px;
}

.connector.second-connector {
    top: 4px;
    right: -19px;
}

.connector.third-connector {
    bottom: -20px;
    left: 4px;
}

.connector.fourth-connector {
    bottom: -20px;
    right: -19px;
}

.color-picker {
    position: relative;
    height: 26px;
    width: 24px;
}

.rounded-color-picker,
.pencilWidth-picker {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
}

.pencilWidth-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.position {
    position: absolute;

}

.pencilWidth-picker {
    border: 1px solid black;

}

.innerPencilWidth {
    border-radius: 50%;
    background-color: black;
}

.canvas-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.canvas-box {
    height: 100%;
    overflow: hidden;
}

.textarea-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.con-slide {
    position: relative;
    display: flex;
    align-items: center;
}

.con-slide .con-slide-icon {
    position: absolute;
    left: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .5px solid var(--black-color);
    border-radius: .8rem;
}

.con-slide .slide-icon {
    width: calc(var(--slide-common-height) - 2px);
    height: var(--slide-common-height);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.con-slide .slide-icon path {
    fill: var(--black-color);
}

.con-slide .slide-icon.active path {
    fill: var(--white-color);
    animation: calc(var(--transition-time) - .1s) make-white;
}

.con-slide .slide {
    position: relative;
    border-radius: 50%;
    width: var(--slide-common-height);
    height: var(--slide-common-height);
    background: var(--black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    left: 0;
}

.con-slide .slide.left {
    animation: var(--transition-time) linear slide-left;
    left: 0;
}

.con-slide .slide.right {
    animation: var(--transition-time) linear slide-right;
    left: 21px;
}

textarea {
    width: 100%;
    border: none;
    resize: none;
    overflow: hidden;
    min-height: 60px;
    box-sizing: border-box;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 10%;
    line-height: 1.5;
    display: block;
    font-weight: var(--font-weight-004);
    color: #C2C2C2;
    outline: none;
}


.clear-button {
    background-color: var(--pure-white);
    color: var(--pure-black);
    border: 1px solid var(--pure-black);
    cursor: pointer;
}

@keyframes slide-left {
    0% {
        left: 21px;
    }
    100% {
        left: 0;
    }
}

@keyframes slide-right {
    0% {
        left: 0;
    }
    100% {
        left: 21px;
    }
}

@keyframes make-white {
    0% {
        fill: var(--black-color);
    }
    100% {
        fill: var(--white-color);
    }
}

@media (min-width: 1200px) {
    .canvas-box {
        flex-grow: 1;
    }
}

@media (min-width: 2500px) {
}