.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    outline: 0;
    transition: opacity .15s linear;
    background: #ffffff;
}

.modal-dialog {
    position: relative;
    width: 94%;
    height: 80%;
    margin: .5rem auto;
}

.cancel-button {
    cursor: pointer;
}